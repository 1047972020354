import React from "react"
import CaseStudyHeaderSection from "./components/CaseStudyHeaderSection"
import CaseStudyDescriptionSection from "./components/CaseStudyDescriptionSection"
import CaseStudyWorkSection from "./components/CaseStudyWorkSection"
import NewsletterSection from "../newsletter/NewsletterSection"

const CaseStudyCrankTemplate = ({ image }) => {
  return (
    <React.Fragment>
      {" "}
      <CaseStudyHeaderSection
        title="Crank Movie"
        description="Designing Hook Points to elevate an independent action film starring Jason Statham and drive ticket sales on a limited marketing budget"
        image={image}
      />
      <div className="video_holder_crank">
        <CaseStudyDescriptionSection
          list={
            <ul>
              <li>
                Established the{" "}
                <span>first-ever YouTube influencer campaign</span> before
                “influencers” was a common term, engaging the biggest YouTube
                users at the time to interact with the stars of the film and
                generate buzz
              </li>
              <li>
                We created strategic Hook Points to{" "}
                <span>generate interest and motivate promotion</span> of a movie
                that was much smaller than most major action films
              </li>
              <li>
                The film earned a reported <span>$42+ million</span> at the box
                office with just a $12 million budget and expanded to a sequel
                that generated additional sales
              </li>
            </ul>
          }
        />

        <iframe
          src="https://fast.wistia.net/embed/iframe/jpcpj7e8wq?videoFoam=true"
          title="Pushing Crank - Full Video"
          allow="autoplay; fullscreen"
          allowTransparency="true"
          frameBorder="0"
          scrolling="no"
          className="wistia_embed"
          name="wistia_embed"
          allowFullScreen
          msallowfullscreen
        />
      </div>
      <CaseStudyWorkSection
        next={"/case-study-strike-social"}
        text="We’ve got more to share about how Hook Points helped Crank."
        subText=" Connect with us"
      />
      <NewsletterSection />
    </React.Fragment>
  )
}

export default CaseStudyCrankTemplate
